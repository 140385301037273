import "./style/initial.pcss";
const splashHtml = require("./splash.html");

if (window.location.pathname !== "/") {
  import("./init-vue");
} else {
  document.querySelector("#app").innerHTML = splashHtml;

  const queryParams: any = window.location.search
    .slice(1)
    .split("&")
    .reduce((map, str) => {
      const pair = str.split("=");
      map[pair[0]] = pair[1];
      return map;
    }, {});

  if (queryParams.error) {
    const errorElement = document.querySelector("#error") as HTMLElement;
    errorElement.classList.add("has-error");

    const errorMessages = {
      noAccess: "Vous n'avez pas accès au panel de gestion. Si vous pensez que c'est une erreur, merci de contacter l'administrateur système.",
      expiredLogin: "Votre connexion a expiré. Merci de vous connecter à nouveau.",
    };

    const errorMessageElem = document.createElement("div");
    errorMessageElem.classList.add("message");
    errorMessageElem.innerText = errorMessages[queryParams.error] || "Unexpected error";
    errorElement.appendChild(errorMessageElem);
  }
}
